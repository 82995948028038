import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import ClickableLink from '../../components/Text/ClickableLink';
import {
  DeviceJourney,
  DeviceJourneyData,
  LiveNotificationResponse,
  NotificationCounter,
  NotificationCounterData,
} from '../../models/NotificationsModels';
import useLiveNotificationsUtils from './useLiveNotificationsUtils';

interface LiveNotificationsTemplateProps {
  liveNotification: LiveNotificationResponse;
  onNavigate: (id: string) => void;
}

function LiveNotificationsTemplate({ liveNotification, onNavigate }: LiveNotificationsTemplateProps) {
  const [t] = useTranslation();
  const { isNotificationCounter, isDeviceJourney, helpUrls } = useLiveNotificationsUtils();

  /**
   * Creates a template for a device type journey error
   */
  const createDeviceJourneyTemplate = () => {
    const data = liveNotification.data as DeviceJourneyData;
    if (liveNotification.status === 'ERROR') {
      return (
        <Trans
          i18nKey={`common.liveNotifications.messages.ERROR_${liveNotification.type}`}
          values={{ user: `${data.user.firstName} ${data.user.lastName}`, eSim: data.device.eid }}
          components={{
            UserLink: (
              <ClickableLink
                onClick={() => onNavigate(liveNotification.id)}
                to={`/esimManager/userDetails/${data.user.id}`}
              />
            ),
            ESimLink: (
              <ClickableLink
                onClick={() => onNavigate(liveNotification.id)}
                to={`/esimManager/userDetails/${data.user.id}/deviceDetails/${data.device.id}?userName=${data.user.firstName} ${data.user.lastName} `}
              />
            ),
            HelpLink: (
              <ClickableLink
                onClick={() => onNavigate(liveNotification.id)}
                to={`${import.meta.env.VITE_LOGOUT_RETURN_URL}${helpUrls[liveNotification.type as DeviceJourney]}`}
                target="_blank"
              />
            ),
          }}
        />
      );
    }
    if (liveNotification.status === 'SUCCESS') {
      return (
        <Trans
          i18nKey={`common.liveNotifications.messages.SUCCESS_${liveNotification.type}`}
          values={{ user: `${data.user.firstName} ${data.user.lastName}`, eSim: data.device.eid }}
          components={{
            UserLink: (
              <ClickableLink
                onClick={() => onNavigate(liveNotification.id)}
                to={`/esimManager/userDetails/${data.user.id}`}
              />
            ),
            ESimLink: (
              <ClickableLink
                onClick={() => onNavigate(liveNotification.id)}
                to={`/esimManager/userDetails/${data.user.id}/deviceDetails/${data.device.id}?userName=${data.user.firstName} ${data.user.lastName} `}
              />
            ),
          }}
        />
      );
    }
    return <div />;
  };

  /**
   * Creates a template for the notification counter
   */
  const createNotificationCounterTemplate = () => {
    if (liveNotification.type === NotificationCounter.NOTIFICATION_COUNTER) {
      return (
        <>
          {t(`common.liveNotifications.messages.NOTIFICATION_COUNTER`, {
            counter: (liveNotification.data as NotificationCounterData).counter,
          })}
        </>
      );
    }
    return <div />;
  };

  /**
   * Returns the template jsx
   */
  const createTemplate = () => {
    let template = <div />;
    if (isDeviceJourney(liveNotification.type)) {
      template = createDeviceJourneyTemplate();
    } else if (isNotificationCounter(liveNotification.type)) {
      template = createNotificationCounterTemplate();
    }
    return template;
  };

  return (
    <Typography
      sx={{
        fontSize: '1rem',
        '> a': {
          fontSize: '1rem',
        },
      }}
    >
      {createTemplate()}
    </Typography>
  );
}

export default LiveNotificationsTemplate;
