import { Box, Divider, RadioGroup, SelectChangeEvent, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../../../components/Dropdown/DropdownModels';
import Radio from '../../../../components/Radio/Radio';
import SectionTitle from '../../../../components/Text/TextStyles';
import { NotificationSetting, NotificationType } from '../../../../models/CompanyOnboardingModels';
import { Tariff } from '../../../../models/MarketsModels';
import { updateRequest } from '../../../../stores/NewConnectionStore';
import { RootState } from '../../../../stores/Store';

const RowsContainer = styled(Box)(() => ({
  paddingBottom: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

const SectionValue = styled(Typography)(() => ({
  lineHeight: 'normal',
  marginLeft: 'auto',
  fontWeight: '700',
}));

const SectionLabel = styled(Typography)(() => ({
  lineHeight: 'normal',
}));

interface UpgradeDeviceSummaryStepProps {
  nextStep: () => void;
  onClose: () => void;
  isLoading: boolean;
  availableTariffs: Tariff[];
}

function UpgradeDeviceSummaryStep({ nextStep, onClose, isLoading, availableTariffs }: UpgradeDeviceSummaryStepProps) {
  const [t] = useTranslation();
  const {
    request,
    device,
    selectedDropdownUser,
    userSelectionType,
    userAlreadyExists,
    newUser,
    qrCodeNotificationSettings,
    hasDistributionList,
  } = useSelector((state: RootState) => state.newConnectionReducer);
  const dispatch = useDispatch();
  const [tariffOptions, setTariffOptions] = useState<DropdownItem[]>([]);
  const [selectedTariff, setSelectedTariff] = useState<string>('');
  const [showTariffError, setShowTariffError] = useState(false);

  useEffect(() => {
    if (availableTariffs.length) {
      const options: DropdownItem[] = availableTariffs.map((tariff) => ({
        value: tariff.code,
        label: tariff.description,
        key: tariff.code,
        data: tariff,
      }));
      setTariffOptions(options);
      if (request.tariffCode) {
        setSelectedTariff(request.tariffCode);
      }
    } else {
      setShowTariffError(true);
    }
  }, [availableTariffs]);

  /**
   * Handle tariff dropdown change
   * @param e
   */
  const handleTariffChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedTariff(e.target.value as string);
    dispatch(updateRequest({ tariffCode: e.target.value as string }));
  };

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as NotificationType;
    dispatch(updateRequest({ qrCodeNotification: val }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {availableTariffs.length > 1 ? (
        <Box>
          <Dropdown
            id="tariffCode"
            name="tariffCode"
            label={t('pages.eSimManager.newConnectionDrawer.tariffCode')}
            value={selectedTariff}
            onChange={(e) => handleTariffChange(e)}
            formStyle={{ paddingBottom: '1.5rem' }}
            items={tariffOptions}
            disabled={!availableTariffs || availableTariffs.length === 1}
            placeholder={
              t('common.placeholders.dropdownPlaceholder') +
              t('pages.eSimManager.newConnectionDrawer.tariffCode').toLocaleLowerCase()
            }
          />
        </Box>
      ) : null}
      {showTariffError ? (
        <Typography sx={{ color: (theme) => theme.palette.state.error, fontSize: '1.125rem', paddingBottom: '1.5rem' }}>
          {t('pages.eSimManager.newConnectionDrawer.noTariffs')}
        </Typography>
      ) : null}

      {/* Summary */}
      <SectionTitle>{t('pages.eSimManager.newConnectionDrawer.summary')}</SectionTitle>
      <RowsContainer>
        <Row>
          <SectionLabel>{t('pages.eSimManager.newConnectionDrawer.userName')}</SectionLabel>
          <SectionValue>{`${
            userSelectionType === 'CREATE' && !userAlreadyExists && newUser
              ? `${newUser.firstName} ${newUser.lastName}`
              : selectedDropdownUser.label
          }`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel sx={{ width: '25%' }}>{t('pages.eSimManager.newConnectionDrawer.eSimId')}</SectionLabel>
          <SectionValue sx={{ width: '75%', textAlign: 'end' }}>{request.eid}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.eSimManager.newConnectionDrawer.device')}</SectionLabel>
          <SectionValue>{`${device?.manufacturer} ${device?.model}`}</SectionValue>
        </Row>
        <Row>
          <SectionLabel>{t('pages.eSimManager.newConnectionDrawer.activationMethod')}</SectionLabel>
          <SectionValue>
            {t(`pages.eSimManager.newConnectionDrawer.${request.notificationType.toLocaleLowerCase()}`)}
          </SectionValue>
        </Row>
        {request.tariffCode ? (
          <Row>
            <SectionLabel>{t('pages.eSimManager.newConnectionDrawer.tariff')}</SectionLabel>
            <SectionValue>
              {(availableTariffs.find((tar) => tar.code === request.tariffCode) as Tariff).description}
            </SectionValue>
          </Row>
        ) : null}
      </RowsContainer>

      {/* Notification */}
      {request.notificationType === 'PULL' ? (
        <>
          <SectionTitle sx={{ paddingTop: '1rem' }}>
            {t('pages.eSimManager.newConnectionDrawer.notificationSettings')}
          </SectionTitle>
          <Box sx={{ paddingBottom: '1rem' }}>
            <Typography sx={{ paddingBottom: '1.5rem' }}>
              {t('pages.eSimManager.newConnectionDrawer.qrCodeQuestion')}
            </Typography>
            <RadioGroup
              aria-labelledby="qr-code-destination"
              name="qr-code-destination"
              value={request.qrCodeNotification}
              onChange={handleRadioChange}
            >
              {qrCodeNotificationSettings.map((ns: NotificationSetting) => (
                <Radio
                  value={ns.type}
                  key={ns.type}
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      {t(`pages.eSimManager.newConnectionDrawer.${ns.type}`)}
                      <Typography sx={{ paddingLeft: '1rem', color: (theme) => theme.palette.text.disabled }}>
                        {ns.label}
                      </Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
        </>
      ) : null}

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.monochrome.monoChrome3,
          marginBottom: '1.5rem',
        }}
      />

      <Box sx={{ marginBottom: '1rem' }}>
        <Checkbox
          checked={request.notifyAdmin}
          onChange={(e) => {
            dispatch(updateRequest({ notifyAdmin: e.currentTarget.checked }));
          }}
          label={t('pages.eSimManager.newConnectionDrawer.sendMeNotifications')}
        />
      </Box>

      {hasDistributionList ? (
        <Box sx={{ marginBottom: '2.5rem' }}>
          <Checkbox
            checked={request.notifyDistributionList}
            onChange={(e) => {
              dispatch(updateRequest({ notifyDistributionList: e.currentTarget.checked }));
            }}
            label={t('pages.eSimManager.newConnectionDrawer.sendDLNotifications')}
          />
        </Box>
      ) : null}

      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} disabled={isLoading} />
          <Button
            label={t('common.send')}
            color="primaryButton"
            onClick={nextStep}
            disabled={isLoading || showTariffError || !selectedTariff}
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default UpgradeDeviceSummaryStep;
